import { toast, Zoom } from "react-toastify";

export const successToast = (msg) => {
  toast.success(msg, {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 1000,
    theme: "colored",
  });
};

export const errorToast = (msg) => {
  toast.error(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
  });
};
export const warningToast = (msg) => {
  toast.warning(msg, {
    position: toast.POSITION.TOP_RIGHT,
    theme: "colored",
  });
};

export const bottomToast = (msg) => {
  toast.info(msg, {
    position: toast.POSITION.BOTTOM_CENTER,
    theme: "colored",
  });
};

export const copyToClipboardToast = (msg) => {
  toast(msg, {
    position: "bottom-center",
    autoClose: 300,
    hideProgressBar: true,
    closeButton: false,
    theme: "light",
    transition: Zoom,
    style: {
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
      marginBottom: "3rem",
      textAlign: "center",
      borderRadius: "0.8rem",
      // padding: 0,
    },
  });
};
